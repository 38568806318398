import React from "react";
import "../../css/Footer.css";
import LazyLinePainter from "lazy-line-painter";
import CorporatePlan from "../Landing/CorporatePlan";
import { Card, Modal, Container } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import { NavLink } from "reactstrap";
import NatalForm from "../Landing/NatalForm";
class Footer extends React.Component {
  state = {
    show: false,
    view: false
  };

  componentDidMount() {
    let els = {
      logo: document.querySelector("#lion")
    };
    let myAnimation;
    myAnimation = new LazyLinePainter(els.logo, {
      strokeWidth: 2.4,
      strokeCap: "round",
      strokeColor: "#a9d372",
      ease: "easeInOutSine",
      drawSequential: true,
      repeat: -1,
      delay: 500
    });
    myAnimation.paint();
    // myAnimation.on("start", onStart);
  }
  changeshow() {
    this.setState({
      show: false
    });
  }
  changeview() {
    this.setState({
      view: false
    });
  }
  callcorporate() {
    this.setState({
      show: true
    });
  }
  callnatal() {
    this.setState({
      view: true
    });
  }
  render() {
    return (
      <>
        <div className="How-it-works">
          <img className="our-customer-img" src={require("./../../assests/How-it-works.svg")} />
        </div>
        <div className="our-customer">
          <img className="our-customer-img" src={require("./../../assests/our-customer.svg")} />
        </div>
      <section className="foot">

        <div className="container-fluid">
          <div
              className="row container-row align-items-start"

          >
              <div className="media-space footer-img col-sm-12 col-lg-6 col-xl-3 text-center">
                <a href="/">
                  <img

                    src={require("../../assests/fitlogo.png")}
                  />
                </a>
                <div className="mt-4 text-left">
                  Lorem ipsum dolor sit amet consectetur adipiscing elitEtiam iaculis velit diam. In suscipit felis purus ut gravida turpis cursus sit amet Proin vitae velit id arcu hendrerit bibendum.
                </div>
                {/*
              <ul className="itemsfoot">
                <a onClick={() => this.props.history.push("/about")}>
                  <li style={{ color: "#D0A26E",  textTransform: "uppercase" }}>About us</li>
                </a>
                <a  onClick={() => this.props.history.push("/diet")}>
                  <li style={{ color: "#D0A26E",  textTransform: "uppercase" }}>Packages</li>
                </a>
                <a onClick={() => this.props.history.push("/diet")}>
                  <li style={{ color: "#D0A26E",  textTransform: "uppercase" }}>How it Works</li>
                </a>
                <a href="#">
                  <li style={{ color: "#D0A26E",  textTransform: "uppercase" }}>Careers</li>
                </a>
                <a onClick={() => this.props.history.push("/contact")}>
                  <li style={{ color: "#D0A26E",  textTransform: "uppercase" }}>Contact us</li>
                </a>
                <a href="#">
                  <li style={{ color: "#D0A26E",  textTransform: "uppercase" }}>Blogs</li>
                </a>
              </ul> */}
            </div>

              <div className=" media-space col-sm-12 col-lg-6 col-xl-2">
                <h4 className="text-color">
                <strong>Services</strong>
              </h4>
                <ul className="itemsfoot ">
                  <a onClick={() => this.props.history.push("/booksession")} >
                    <li style={{ fontSize: '14px', textTransform: "uppercase", fontFamily: "Roboto" }}>Weighloss & Toning</li>
                  </a> <a onClick={() => this.props.history.push("/booksession")} >
                    <li style={{ fontSize: '14px', textTransform: "uppercase" }}>Cardio fitness</li>
                  </a>
                  <a onClick={() => {
                    this.callnatal();
                  }}>
                    <li style={{ fontSize: '14px', textTransform: "uppercase" }}>Pre/Post Natal exercise</li>
                  </a>
                  <a onClick={() => {
                    this.callcorporate();
                  }}>
                    <li style={{ fontSize: '14px', textTransform: "uppercase" }}>Weight/resistance training</li>
                  </a>
                  <a onClick={() => {
                    this.callcorporate();
                  }}>
                    <li style={{ fontSize: '14px', textTransform: "uppercase" }}>Corporate Fitness</li>
                  </a>
                <a onClick={() => this.props.history.push("/booksession")}>
                    <li style={{ fontSize: '14px', textTransform: "uppercase" }}>Youth and team fitness</li>
                </a>
                <a onClick={() => this.props.history.push("/booksession")}>
                    <li style={{ fontSize: '14px', textTransform: "uppercase" }}>Yoga Training</li>
                </a>
                  <a onClick={() => this.props.history.push("/booksession")}>
                    <li style={{ fontSize: '14px', textTransform: "uppercase" }}>Group training</li>
                  </a>
                  {/* <a onClick={() => this.props.history.push("/diet")}>
                  <li style={{ fontSize:'14px',textTransform: "uppercase" }}>Diet Plans</li>
                </a>
                <a onClick={() => this.props.history.push("/booksession")} >
                  <li style={{ fontSize:'14px',textTransform: "uppercase" }}>Fitness Plans</li>
                </a> */}
                <a onClick={() => this.props.history.push("/institute")}>
                    <li style={{ fontSize: '14px', textTransform: "uppercase" }}>
                      Sports Conditioning
                  </li>
                </a>

                </ul>
              </div>
              <div className="media-space col-sm-12 col-lg-6 col-xl-2">
                <h4 className="text-color">
                  <strong>How it Works</strong>
                </h4>
                <ul className="itemsfoot faq_item_mq">
                  <a onClick={() => this.props.history.push("/TermsConditions")}>
                    <li style={{ color: "#D0A26E", textTransform: "uppercase" }}>packages</li>
                </a>
                  <a onClick={() => this.props.history.push("/Privacy")}>
                    <li style={{ color: "#D0A26E", textTransform: "uppercase" }}>careers</li>
                </a>
                  <a onClick={() => this.props.history.push("/Refund")}>
                    <li style={{ color: "#D0A26E", textTransform: "uppercase" }}>Blogs</li>
                  </a>
                  <a onClick={() => this.props.history.push("/Refund")}>
                    <li style={{ color: "#D0A26E", textTransform: "uppercase" }}>Contact us</li>
                  </a>
              </ul>

            </div>
              <div className="media-space col-sm-12 col-lg-6 col-xl-2">
                <h4 className="text-color"> 
                <strong>FAQ</strong>
              </h4>
              <ul className="itemsfoot faq_item_mq">
                  <a onClick={() => this.props.history.push("/Privacy")}>
                    <li style={{ color: "#D0A26E", textTransform: "uppercase" }}>about us</li>
                  </a>
                <a onClick={() => this.props.history.push("/TermsConditions")}>
                  <li style={{ color: "#D0A26E",  textTransform: "uppercase" }}>Terms <span style={{fontFamily: "Roboto"}}>&</span> Conditions</li>
                  </a>
                <a onClick={() => this.props.history.push("/Refund")}>
                  <li style={{ color: "#D0A26E",  textTransform: "uppercase" }}>Refund Policy</li>
                </a>
              </ul>

            </div>
              <div className="media-space col-sm-12 col-lg-6 col-xl-3
             ">
                <div className="d-flex align-items-center justify-content-center">
                  <div>
                    <img src={require("./../../assests/app_store.png")} />
                    <img className="mt-4" src={require("./../../assests/google-play.png")} />
                  </div>
                  <img className="ml-4" src="https://cdn.animaapp.com/projects/664e4c4c9c55ddf5654a5c88/releases/6663568a7e286e30e29dcfeb/img/layer-1.svg"
                          alt="asset1"
                  />
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 79.46 79.51"
                data-llp-composed="true"
                id="lion"
                className="lazy-line-painter"
                  >
              </svg>
                </div>
              
            </div>

            <Modal show={this.state.show} onHide={() => this.changeshow()}>
              <Modal.Header closeButton></Modal.Header>
              <CorporatePlan onHide={() => this.changeshow()} />
            </Modal>

            <Modal
              show={this.state.view}
              onHide={() => this.changeview()}
              className="col-sm-12"
            >
              <Modal.Header closeButton></Modal.Header>
              <NatalForm onHide={() => this.changeview()} />
            </Modal>
          </div>
        </div>
      </section>
      </>

    );
  }
}
export default withRouter(Footer);
