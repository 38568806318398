import React from "react";
import "../../css/termsAndConditions.css";
import Header from "./../Header/index";
import Footer from "./../Footer/Footer";
class About extends React.Component {
  render() {
    return (
      <>
        <div className="termsAndConditionsContainer">
          <Header />

          <div className="container-row">
            <div className="col-sm-12 topMargin">
              <div className="TermsCenter">
                <h1 className="termsHeader">About Us</h1>

                {/* <div className="topParagraph">
                  Read our terms below to learn more about your rights and
                  responsibilities as a Fitfinity user.
                </div> */}
              </div>
              <img
                className="termsandConditionsImage"
                src={require("../../assests/hand.png")}
                // style={{ width: "30%" }}
              />
            </div>
          </div>
          <hr />

          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-2"></div>
              <div className="col-sm-8">
                <div className="feedback">
                Start your wellness journey with Fitfinity. We offer personalized home training, yoga sessions, and custom diet plans tailored just for you.
                </div>
                <div className="feedback">
                Unlock your potential with our dynamic personal strength training sessions right in the comfort of your home. Our expert trainers bring the gym to you, ensuring you build muscle, boost endurance, and achieve your fitness goals without stepping out the door. Get personalised workouts tailored to your needs, and see transformative results that will keep you motivated and coming back for more.

                </div>
                
              </div>
              <div className="col-sm-2"></div>
            </div>
          </div>
        </div>
        <br />
        <Footer />
      </>
    );
  }
}

export default About;
