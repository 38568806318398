import React from "react";
import "../../css/termsAndConditions.css";
import Header from "../Header/index";
import Footer from "../Footer/Footer";
class Refund extends React.Component {
  render() {
    return (
      <>
        <div className="termsAndConditionsContainer">
          <Header />

          <div className="container-row">
            <div className="col-sm-12 topMargin">
              <div className="TermsCenter">
                <h1 className="termsHeader">Refund Policy</h1>

                {/* <div className="topParagraph">
                  Read our terms below to learn more about your rights and
                  responsibilities as a Fitfinity user.
                </div> */}
              </div>
              <img
                className="termsandConditionsImage"
                src={require("../../assests/hand.png")}
                // style={{ width: "30%" }}
              />
            </div>
          </div>
          <hr />

          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-2"></div>
              <div className="col-sm-8">
                <div className="feedback">
                  This Policy is effective as of
                  01-January-2024.
                </div>
                <div className="feedback">
                At Fitfinity Trainer, we take pride in the services delivered by us and guarantee your satisfaction with our services and support. We constantly improve and strive to deliver the best accounting, financial or secretarial services through the internet. However, in case you are not satisfied with our services, please contact us immediately and we will correct the situation, provide a refund or offer credit that can be used for future Fitfinity Trainer orders.
                </div>
                <div className="feedback">
                  <strong> 30 Day Refund Policy</strong>
                </div>
                <div className="feedback">
                If an engagement is not progressing as per your expectations, you can request for a refund within 30 days of payment. After 30 days, you can request for the refund to be processed as a service credit - which can be used for any other future service.
                </div>
                <div className="feedback">
                Earned Fee & Cancellation Fee Deduction
                </div>
                <div className="feedback">
                  You acknowledge that the Website serves as a venue for the
                  online distribution and publication ofuser submitted
                  information between Service Professionals and Service Users,
                  and, by using, visiting, registering for, and/or otherwise
                  participating in this Website, including the Services
                  presented, promoted, and displayed on the Website, and by
                  clicking on "I have read and agree to the terms of use," you
                  hereby certify that: (1) you are either a Service Professional
                  or a prospective Service User, (2) you have the authority to
                  enter into these Terms of Use, (3) you authorize the transfer
                  of payment for Services requested through the use of the
                  Website, and (4) you agree to be bound by all terms and
                  conditions of these Terms of Use and any other documents
                  incorporated by reference herein. If you do not accept the
                  terms and conditions then please do not proceed with using
                  this website.
                </div>
                <div className="feedback">
                  <strong>
                  Before processing any refund, we reserve the right to make a best effort to complete the service. In case you are not satisfied with the service, a cancellation fee of 20% + earned fee + fee paid to the government would be applicable. In case of change of service, the cancellation fee would not be applicable.
                  </strong>
                </div>
                <div className="feedback">
                  <strong> Change of Service</strong>
                </div>
                <div className="feedback">
                If you want to change the service you ordered for a different one, you can request this change of service within 60 days of purchase. The purchase price of the original service, less any earned fee and money paid to government entities, such as filing fees or taxes, or to other third parties with deducted and the next service will be launched. In case of change of service to the same value or a higher value.
                </div>
                
                <div className="feedback">
                  <strong>
                  Request Refund
                  </strong>
                </div>
                <div className="feedback">
                To initiate a refund request, You will required to send an email to contact@fitfinitytrainer.com along with details of the transaction to begin the refund process. Refunds are normally processed within 3-5 weeks from the date of request along with all relevant information.
                </div>
               
                <div>
                  <strong>
                    Fitfinity India Technology Private Limited. All Rights Reserved
                  </strong>
                </div>
              </div>
              <div className="col-sm-2"></div>
            </div>
          </div>
        </div>
        <br />
        <Footer />
      </>
    );
  }
}

export default Refund;
