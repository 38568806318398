const prodConfig = {
  apiKey: "rzp_live_rMHmp5azQNytnS",
  secret: "gfN4htSDYOC7A2X3wm4ntrfX"

};
const devConfig = {
  apiKey: "rzp_test_dNtTvvdN1tX5Dl",
  secret: "zldwnx801PHLCOZiyn0qNmak"
};
export const config = devConfig;
  // process.env.NODE_ENV === "production" ? prodConfig : devConfig;